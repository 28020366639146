// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import JSONData from "../../content/career.json"

type Data = {
  site: {
    siteMetadata: {
      title: string
    }
  }
}

const BlogIndex = ({ data, location }: PageProps<Data>) => {
  const siteTitle = data.site.siteMetadata.title
  console.log(JSONData.projects)
  return (
    <Layout location={location} title={`${siteTitle}`}>
      <SEO title="Professional Experience of Rohit Sharma" />

      <h2>{JSONData.title.toUpperCase()}</h2>
      {
        JSONData.projects.map(({title, excerpt, relativeLink, displayLink, responsibilities }) => {
          return (
            <div>
              <h4 style={{marginBottom: 0}}>{title}</h4>
              <p style={{margin: 0, color: 'rgb(108 105 105)'}}>{excerpt.split("<br>").join(" | ")}</p>
              <div dangerouslySetInnerHTML={{
                        __html: responsibilities
                      }}
              ></div>
            </div>
          )
        })
      }
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`